import { getRoot } from "mobx-state-tree"
import { api_upscale_asset } from "../../../queries/job"
import { _throw } from "../../../utils/error-service"

export const jobActions = self => ({

  update(job) {
    self.set('id', job.id)
    self.set('startedAt', job.startedAt)
    self.set('status', job.status)
    // self.set('userId', job.userId)
    self.set('presetId', job.preset?.id)
    self.set('assetId', job.assetId)
    self.set('generatedAsset', job.generatedAsset)
    self.set('upscaledAsset', job.upscaledAsset)
    self.set('aspectRatio', job.aspectRatio)
    self.set('notifyMe', job.notifyMe)
    self.set('seed', Number(job.seed))
  },
  async onUpscaleAsset() {
    try {

      self.set('upscaling', true)

      const response = await api_upscale_asset(self.accountStore.auth0AccessToken, self.id)
      if (response.error) throw response

      const aid = getRoot(self).assetsFactory.addUpdateAsset(response.data.data)

      self.set("upscaledAsset", aid)

      self.set('upscaling', false)

      self.accountStore.set('tokens', response.data.metadata.tokens)


      return response.data.data

    } catch (err) {

      self.set('upscaling', false)

      const out_of_tokens = err.response?.data.message === "OUT_OF_TOKENS"

      if (out_of_tokens) {
        self.guiStore.openPaywallModal()
      }

      return {
        ...err,
        error: true
      }
    }
  },
  incrementCounter() {
    self.runningTime++;
  },
  set(key, value) {
    self[key] = value
  }
})