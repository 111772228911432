import axios from "axios"
import { _throw } from "../utils/error-service"
import { API } from '../constants/system'

export const api_get_user_subscribtion_status = async (token) => {
  try {
    const res = await axios.get(`${API}/check-subscription`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_user = async (token) => {
  try {
    const res = await axios.get(`${API}/me`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_pricing_table = async (token) => {
  try {
    const res = await axios.get(`${API}/show-pricing-table`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_check_if_merge_accounts = async (token) => {
  try {
    const res = await axios.get(`${API}/link-check`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })

    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_merge_accounts = async (token, data) => {
  try {
    const res = await axios.post(`${API}/link`, data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })

    return res
  } catch (err) {
    return _throw(err)
  }
}


export const api_get_checkout_session = async (token, type = "DEFAULT", price) => {
  
  try {

    let priceObject = {}
    if (price) priceObject = { price }

    const res = await axios.post(`${API}/create-checkout-session`, {
      type,
      mode: "embedded",
      ...priceObject
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }

}

export const api_get_packages = async (token, priceId) => {
  try {

    const res = await axios.get(`${API}/packages?priceId=${priceId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })

    return res

  } catch (err) {
    return _throw(err)
  }
}

export const api_get_credits = async (token) => {
  try {
    const res = await axios.get(`${API}/credits`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })

    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_used_products = async (token) => {
  try {
    const res = await axios.get(`${API}/products-generated-with`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}
