import { api_post_image } from "../../../queries/image"
import { api_get_custom_presets, api_get_presets, api_make_preset_mine, api_make_preset_public, api_save_preset_to_user } from "../../../queries/prompt"
import { api_get_jobs } from "../../../queries/job"
import map from 'lodash/map'
import find from 'lodash/find'
import { _track } from "../../../services/event-track-service"
import { EVENTS } from "../../../constants/events"
import { CREDITS, PRESET_TYPES, TOKEN_PRICES } from "../../../constants/status"
import { api_guess_product_type } from "../../../queries/product"
import toast from "react-hot-toast"
import { api_animate } from "../../../queries/generate"
import { onSnapshot } from "mobx-state-tree"

export const generateImageStoreActions = self => ({  

  updateUserPromptFromPreset() {
    const parsedPrompt = self.parsePresetPrompt(self.selectedPreset)
    self.set("userPrompt", parsedPrompt)
  },

  parsePresetPrompt(preset) {
    if (!preset || !preset.prompt) {
      return '';
    }

    let parsedPrompt = preset.prompt;



    if (self.selectedProduct && self.selectedProduct.triggerLora) {
      parsedPrompt = parsedPrompt.replace(/\/\/\*\*\/\//, self.selectedProduct.triggerLora);
    } else if (self.trainStore.selectedTraining?.triggerWord) {
      parsedPrompt = parsedPrompt.replace(/\/\/\*\*\/\//, self.trainStore.selectedTraining?.triggerWord)
    }

    return parsedPrompt;
  },

  async waitForLoadingImage() {
    return new Promise((resolve, reject) => {
        let elapsedTime = 0;

        const checkLoadingImage = () => {
            if (!self.isImageUploading) {
                resolve();
            } else if (elapsedTime >= 60000) { 
                reject(new Error("Waited for 1 minute, but loadingImage is still true."));
            } else {
                setTimeout(checkLoadingImage, 50); 
                elapsedTime += 50; 
            }
        };

        checkLoadingImage();
    });
  },

  async renderImage() {
    try {

      self.set('loading', true)
      self.set('loadingRender', true)

      self.addMultipleFakeJobs(self.settingsStore?.numberOfImages || 1)

      await self.waitForLoadingImage()

      const settings = self.settingsStore.settings

      let data = {
        assetId: self.selectedProduct.asset?.id,
        presetId: self.selectedPresetId,
        
        productType: self.selectedProduct.productType,
        productId: self.selectedProduct.id,
        preset: {
          custom: self.isCustomSceneSelected,
          prompt: self.userPrompt,
          negativePrompt: self.userPromptNegativeView,
          seed: self.userPromptSeedView
        },
        settings,
        adminSettings: self.accountStore.isAdmin ?
          self.adminGenerateSettings : null
      }

      if (self.settingsStore.isComfyWorkflow) {
        // add reference image
        data = {
          ...data,
          referenceAssetId: self.selectedReferenceImage.id
        }
      }

      const res = await api_post_image(data, self.accountStore.auth0AccessToken)

      if (res.error) throw res

      const ids = await self.jobsFactory.addUpdateJobs(res.data.data.jobs)
      //self.set('jobs', ids)
      self.addJobIds(ids)
      self.set('loading', false)
      self.set('loadingRender', false)
      self.set('fakeJobs', [])
      self.checkJobsStatusAndUpdate()

      if (res.data.metadata?.tokens)
        self.accountStore.set('tokens', res.data.metadata?.tokens)

      _track(EVENTS.GENERATE_PHOTO)

      return res

    } catch (err) {
      const out_of_tokens = err.response?.data?.message === "OUT_OF_TOKENS"

      if (out_of_tokens) {
        self.guiStore.openPaywallModal()
      }

      self.set('loading', false)
      self.set('loadingRender', false)
      self.set('fakeJobs', [])
    }
},

async runAnimate(jobId, mask, prompt) {

  try {
    
    self.set('loading', true)
    self.set('loadingRender', true)

    self.addMultipleFakeJobs(1)

    await self.waitForLoadingImage()

    const data = {
      jobId,
      prompt,
      base64Mask: mask
    }

    const res = await api_animate(data, self.accountStore.auth0AccessToken)

    if (res.error) throw res

    const ids = await self.jobsFactory.addUpdateJobs(res.data.jobs)
      //self.set('jobs', ids)
    self.addJobIds(ids)
    self.set('loading', false)
    self.set('loadingRender', false)
    self.set('fakeJobs', [])
    self.checkJobsStatusAndUpdate()

    return res

  } catch (err) {

    const out_of_tokens = err.response?.data?.message === CREDITS.OUT_OF_TOKENS

    if (out_of_tokens) {
      self.guiStore.openPaywallModal()
    }

    self.set('loading', false)
    self.set('loadingRender', false)
    self.set('fakeJobs', [])

    return {
      error: true,
      ...err
    }
  }

},

generateNegativeId() {
  self.negativeIdCounter += 1;
  return -self.negativeIdCounter;
},

addFakeJob() {
  const placeholder = {
    id: self.generateNegativeId(),
    status: "INITIALIZING",
    aspectRatio: self.settingsStore.aspectRatio
  };
  self.fakeJobs.push(placeholder);

},

addMultipleFakeJobs(count) {
  for (let i = 0; i < count; i++) {
    self.addFakeJob();
  }
},

addToTheTopOfJobs(job) {
  self.jobs.unshift(job)
},

addJobIds(ids) {
  if (self.jobs === null || self.jobs?.length === 0 ) {
    self.set('jobs', ids)
  } else {
    self.jobs = ids.concat(self.jobs);
  }
},

async getFinetunedPresets() {
  try {
    self.set('loading', true)
    const res = await api_get_presets(self.accountStore.auth0AccessToken, { type: PRESET_TYPES.FINETUNED })
    if (res.error) throw res
    const ids = await self.presetsFactory.addUpdatePresets(res.data.data.presets)
    self.set('finetunedPresets', ids)

    self.set('loading', false)
    return res
  } catch (err) {
    console.log(err)
    self.set('loading', false)
    return err
  }
},
async getPresets() {
  try {
    self.set('loading', true)
    const res = await api_get_presets(self.accountStore.auth0AccessToken)

    if (res.error) throw res
    const ids = await self.presetsFactory.addUpdatePresets(res.data.data.presets)
    self.set('presets', ids)

    self.set('loading', false)
    return res

  } catch (err) {
    self.set('loading', false)
    console.log(err)
  }
},
async getCustomPresets() {
  try {
    self.set('loading', true)
    const res = await api_get_custom_presets(self.accountStore.auth0AccessToken)

    if (res.error) throw res
    const ids = await self.presetsFactory.addUpdatePresets(res.data.presets)
    self.set('customPresets', ids)
    self.set('loading', false)
    return res

  } catch (err) {
    self.set('loading', false)
    console.log(err)
  }
},

async setImageForRender(assetData) {
  const assetId = self.assetsFactory.addUpdateAsset(assetData)
  self.set('selectedImage', assetId)
},

async getMyJobs(reset = true) {
  try {

    self.set("loadingJobs", false)
    if (self.loadingJobs) return

    self.set("loadingJobs", true)
    if (reset) {
      self.set('jobPage', 1)
      self.set('jobEmpty', false)
    }


    let productIds = []
    if (self.accountStore.filterProduct) {
      productIds = [self.accountStore.filterProduct.id]
    }
    

    const res = await api_get_jobs(self.accountStore.auth0AccessToken, [], self.jobPage, productIds);
    const ids = await self.jobsFactory.addUpdateJobs(res.data.data.jobs);

    if (reset)
      self.set('jobs', ids)
    else 
      self.pushJobs(ids)
    

    if (ids.length === 0) self.set('jobEmpty', true)
    
    self.accountStore.getUsedProducts()

    self.set('jobPage', self.jobPage + 1)

    self.set("loadingJobs", false)

    self.checkJobsStatusAndUpdate();
  } catch (err) {
    self.set("loadingJobs", false)
    console.log(err)
  }
},

pushJobs(ids) {
  self.jobs.push(...ids)
},

startPolling() {
  if (!self.intervalId) {
      self.intervalId = setInterval(async () => {
          try {
              const runningIds = map(self.runningJobs, 'id')
              const res = await api_get_jobs(self.accountStore.auth0AccessToken, runningIds);

              if (res.error) throw res;

              await self.jobsFactory.addUpdateJobs(res.data.data.jobs);
              
              self.checkJobsStatusAndUpdate();
          } catch (err) {
              console.error('Error updating jobs:', err);
          }
      }, 5000);
  }
},

stopPolling() {
  if (self.intervalId) {
      clearInterval(self.intervalId);
      self.intervalId = null;
  }
},

checkJobsStatusAndUpdate() {
  const processingJobs = self.jobs?.filter(job => job.status === "RUNNING");

  self.set("runningJobs", processingJobs)
  const runningIds = map(self.runningJobs, 'id')
  
  if (runningIds.length > 0) {
      self.startPolling();
  } else {
      self.stopPolling();
  }
},

onboardingFirstStepCompleted() {
  self.guiStore.closeUploadOnboardingModal()
  self.guiStore.openPresetsModal()
},

async onboardingSecondStepCompleted() {
  self.guiStore.closePresetsModal()
  self.selectedProduct.set('tempType', self.productType)
  await self.selectedProduct.updateProduct()
  self.renderImage()
},

validImage() {
  if (self.isAssetLocalOrUploadingOrOnline === false  ) self.set('selectedImageError', "Upload your product.")
  else self.set('selectedImageError', null)
},
validProductType() {
  if (self.productType === null  ) self.set('productTypeError', "Write what best describes your product: can, bottle, parfume.")
  else self.set('productTypeError', null)
},
validPreset() {
  if (self.selectedPreset === null && self.isCustomSceneSelected === false ) self.set('selectedPresetError', "Choose a Scene or turn on manual mode by flicking the switch.")
  else self.set('selectedPresetError', null)
},

clearInputs() {
  self.set('selectedImageError', null)
  self.set('selectedPresetError', null)
  self.set('productTypeError', null)
},

setProduct(product) {
  const id = self.productsFactory.addUpdateProduct(product)

  self.fetchProductType(id)

  self.accountStore.pushProductToTheTop(id)
  self.set('selectedProduct', id)
},

setReferenceImage(data) {
  const assetId = self.assetsFactory.addUpdateAsset(data)
  self.set("selectedReferenceImage", assetId)
},

async fetchProductType(productId) {

  try {
    const res = await api_guess_product_type(self.accountStore.auth0AccessToken, productId)
    if (res.error) throw res

    if (res.data.data.type) {
      const product = self.productsFactory.findProduct(Number(res.data.data.productId))

      product.guessProductType(res.data.data.type)
    }
  } catch (err) {
    console.log(err)
  }
},

async makePresetPublic(jobId, title) {
  try {
    const res = await api_make_preset_public(self.accountStore.auth0AccessToken, {
      jobId,
      title
    })
    if (res.error) throw res

    toast.success(`Preset made public, title: ${title}. Preset ID: ${res.data?.data?.id} `)

    self.getPresets()
    return res
  } catch (err) {
    toast.error(err.response?.data?.message || "Something went wrong")
    return err
  }
},

async savePresetToUser(jobId, title, email) {
  try {
    const res = await api_save_preset_to_user(self.accountStore.auth0AccessToken, {
      jobId,
      title,
      email
    })
    if (res.error) throw res

    toast.success(`Template saved to user.`)

    self.getPresets()
    return res
  } catch (err) {
    toast.error(err.response?.data?.message || "Something went wrong")
    return err
  }
},

async makePresetMine(jobId) {
  try {

    // check if this preset is already in user's library
    const job = find(self.jobs, j => j.id === jobId)
    const presetId = job.presetId

    let preset = find(self.presets, p => p.id === presetId)
    if (preset?.id) {
      self.guiStore.setTemplateGlow()
      self.set("selectedPreset", preset.id)
      self.set("selectedIndex", preset.id)
      return toast.success("This is a public preset.")
    }
    // check if this preset is already a public preset

    preset = find(self.customPresets, p => p.id === presetId)
    if (preset?.id) {
      self.guiStore.setTemplateGlow()
      self.set("selectedPreset", preset.id)
      self.set("selectedIndex", preset.id)
      return toast.success("You already saved this preset to your library!")
    }

    const res = await api_make_preset_mine(self.accountStore.auth0AccessToken, {
      jobId
    })

    if (res.error) throw res

    self.getCustomPresets()

    toast.success(`Template saved to your private templates library.`)
  } catch (err) {
    console.log(err)
    toast.error(err.response?.data?.message || "Something went wrong")
  }
},

set(key, value) {
  self[key] = value
}

})
