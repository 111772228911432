export function rearrangeObject(obj, keyToMove) {
  // Create a new object to store the rearranged entries

  if (!keyToMove) return obj

  if (!obj.hasOwnProperty(keyToMove)) {
    return obj;
  }

  // Create a new object starting with the desired key
  // Create a new object with non-numeric keys
  const newObj = {};
  
  // First, add the chosen key with a non-numeric prefix
  newObj['_' + keyToMove] = obj[keyToMove];

  // Then, add the rest of the keys with a non-numeric prefix
  for (let key in obj) {
    if (key !== keyToMove) {
      newObj['_' + key] = obj[key];
    }
  }

  return newObj;
}

// generate a random number bitween min and max
export function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}