import { api_post_image } from "../../../queries/image"
import { useAuth0 } from "@auth0/auth0-react";
import { api_get_user_subscribtion_status, api_get_pricing_table, api_get_user, api_check_if_merge_accounts, api_merge_accounts, api_get_checkout_session, api_get_packages, api_get_credits, api_get_used_products } from "../../../queries/user";
import { toJS } from "mobx";
import { getRoot } from "mobx-state-tree"
import { Navigate } from "react-router-dom";
import { _identify } from "../../../services/event-track-service";
import { api_get_products } from "../../../queries/product";
import { api_get_models } from "../../../queries/system";
import { find } from "lodash";

export const accountStoreActions = self => ({   
  setUser(user) {
    self.set('auth0Id', user.sub)
    self.set('email', user.email)
    self.set('emailVerified', user.email_verified)
    self.set('name', user.name)
    self.set('pictureUrl', user.picture)
    self.set('role', user.role)
  },
  logOutUser() {
    getRoot(self).reset()
  },
  setAuth0AccessToken(token) {
    self.set('auth0AccessToken', token)
  },
  async getSubscriptionStatus() {
    try {
      self.set('loading', true)
      self.set('checkoutSessionUrl', null)

      self.set("loadingCheckoutSession", true)

      const res = await api_get_user_subscribtion_status(self.auth0AccessToken)

      if (res.error) throw res
      self.set('hasActiveStripeSubsciption', res.data.data.active)
      self.set('isTrial', res.data.data.trial)
      self.set('stripeCustomerId', res.data.data.customerId)
      self.set('plan', res.data.data.plan ? res.data.data.plan : "DEFAULT")
      self.set('loading', false)

      if (res.data.data.active) {
        getRoot(self).set('loadingScreen', false)
        self.set("loadingCheckoutSession", false)
      }

      return res

    } catch (err) {
      self.set('loading', false)
      self.set("loadingCheckoutSession", false)
      console.log(err)
    }
  },

  async getUser() {
    try {
      self.set('loading', true)
      const res = await api_get_user(self.auth0AccessToken)

      if (res.error) throw res
      self.set('email', res.data.data.email)
      self.set('loading', false)
      self.set('role', res.data.data.role)
      self.set('userId', res.data.data.id)
      self.set('intercomHash', res.data.data.intercomHash)

      self.set('tokens', res.data.data.tokens)

      self.getCredits()

      _identify(
        res.data.data.authOId,
        res.data.data.email
      )

      // if (res.data.data.role === "ADMIN") {
      api_get_models(self.auth0AccessToken)
        .then(res => {
          if (res?.data?.data) {
            self.settingsStore.set('models', res.data.data)

            const defaultModel = find(res.data.data, d => d.default)

            self.settingsStore.set('model', defaultModel.id)
          }

          // self.settingsStore.set('model', find(res.data.data, d => d.default).id)
        })
      // }

      self.settingsStore.getLoras()

      self.set('loading', false)
      return res

    } catch (err) {
      self.set('loading', false)
      console.log(err)
    }
  },

  async getPricingTable() {
    try {
      self.set('loading', true)
      const res = await api_get_pricing_table(self.auth0AccessToken)

      if (res.error) throw res
      // self.set('hasActiveStripeSubsciption', res.data.data.active)
      // self.set('stripeCustomerId', res.data.data.customerId)
      self.set('pricingTableClientSecret', res.data.data.session.client_secret)
      self.set('loading', false)
      return res

    } catch (err) {
      self.set('loading', false)
      console.log(err)
    }
  },

  async getCheckoutSession(price) {
    try {

      self.set('loadingCheckoutSession', true)

      self.set('checkoutSessionUrl', null)

      const res = await api_get_checkout_session(self.auth0AccessToken, self.plan, price)// 
      if (res.error) throw res

      self.set('checkoutSessionUrl', res.data.data.url)
      self.set('clientSecretStripe', res.data.data.clientSecret)
      self.set('currentPlan', res.data.data.price)

      self.set('loadingCheckoutSession', false)
      getRoot(self).set('loadingScreen', false)

      return res.data

    } catch (err) {
      self.set('loadingCheckoutSession', false)
      getRoot(self).set('loadingScreen', false)
      console.log(err)
    }
  },

  async checkIfMergeAccounts() {
    try {
      const res = await api_check_if_merge_accounts(self.auth0AccessToken)

      if (res.data.duplicates) {
        self.set('duplicates', res.data.others)
        self.guiStore.openMergeAccountsModal()
      }
    } catch (err) {
      console.log(err)
    }
  },

  
  async getUsedProducts() {
    try {
      const res = await api_get_used_products(self.auth0AccessToken)

      const pids = self.productsFactory.addUpdateProducts(res.data.products)

      self.set('usedProducts', pids)

      return res
    } catch (err) {
      console.log(err)
      return err
    }
  },


  async mergeAccounts(primaryAccount) {
    try {
      const res = await api_merge_accounts(self.auth0AccessToken, {
        primaryAccount
      })

      window.location.reload()

      return res.data
    } catch (err) {
      console.log(err)
    }
  },

  async getMyProducts() {
    try {
      const res = await api_get_products(self.auth0AccessToken)

      const pids = self.productsFactory.addUpdateProducts(res.data.data.products)
      const publicPids = self.productsFactory.addUpdateProducts(res.data.data.publicProducts)

      self.set('products', pids)
      self.set('publicProducts', publicPids)

      return res

    } catch (err) {
      console.log(err)
      return err
    }
  },

  async getPackages(priceId) {
    try {
      const res = await api_get_packages(self.auth0AccessToken, priceId)

      if (res.error) throw res

      self.set('plans', res.data)

      // self.set('upgradePrice', res.data[0].id)

      return res
    } catch (err) {
      return err
    }
  },

  async getCredits() {
    try {

      const res = await api_get_credits(self.auth0AccessToken)

      if (res.error) throw res

      self.set('tokensLeft', res.data.data.tokens)
      self.set('aiProductsLeft', res.data.data.aiProducts)
      self.set('scenesLeft', res.data.data.scenes)

      return res

    } catch (err) {
      return err
    }
  },

  upgradeToPro() {
    self.set('upgradePrice', self.plans[0].id)
  },

  pushProductToTheTop(productId) {
    self.products.unshift(productId)
  },

  set(key, value) {
    self[key] = value
  }
})